import React, { useState, useEffect, useContext } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import { StepContext } from "../../../Context/StepContext";
import { Accordion, Card, Button, Collapse } from "react-bootstrap";
// import "../script.js";
const StepFour = () => {
  const [activePlan, setActivePlan] = useState("local");
  const { setActiveStep, formData, updateFormData, addToCart } = useContext(StepContext);

  const [stepFourData, setStepFourData] = useState([
    { rowId: 1, bookingDate: "", postcode: "" },
    { rowId: 2, bookingDate2: "", postcode2: "" },
  ]);


  const handleInputChange = (event, rowId) => {
    setStepFourData((prevData) => {
      const updatedRow = prevData.find((row) => row.rowId === rowId);
      updatedRow[event.target.name] = event.target.value;
      return [...prevData];
    });
  };


  const handleSaveStepFourData = (rowId) => {
    const rowData = stepFourData.find((row) => row.rowId === rowId);
    const stepTwoData = formData.stepTwoData; // Get the step two data from the context
    const stepOneData = formData.stepOneData; // Get the step one data from the context
    // Create a new object that combines the step four data with the step two data
    const cartItem = {
      ...rowData,
      fname: stepTwoData.fname || " ",
      lname: stepTwoData.lastname || " ",
      phone: stepTwoData.phone || " ",
      carNumber: stepOneData.carRegistration || " ",
      Model: stepOneData.model || " ",
      motExpiryDate: stepOneData.motExpiryDate || " ",
      firstRegistrationDate:stepOneData.firstRegistrationDate || " ",
      estimatedMileage: stepOneData.estimatedMileage || " ",

    };

    addToCart("stepFourData", cartItem);
    console.log("cart items is : ", cartItem);
    updateFormData("stepFourData", cartItem);
    console.log(`stepFourData saved for row ${rowId}`, cartItem);
    setActiveStep(5);
  };

  const [openSteps, setOpenSteps] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
  });

  const toggleStep = (step) => {
    setOpenSteps((prevState) => ({
      ...prevState,
      [step]: !prevState[step],
    }));
  };

  const handleButtonClick = (plan) => {
    setActivePlan(plan);
  };

  return (
    <div className="container g-0 mt-4">
      <div className="row ">
        {/* first row  */}
        <div className="d-flex Optional_Additions justify-content-between flex-row">
          <h3>
            <b>4: My Optional Additions</b>
          </h3>
          <p>
            <span className="text-danger">*</span>Mandatory field
          </p>
        </div>
        {/* second row */}
        <div className="second_row">
          <h6>
            <b>
              Save money with our optional additions! Why not spread the cost of
              your MOT and services totally interest-free?
            </b>
          </h6>
        </div>
        {/* third row */}
        <div className="row id_4_third_row mt-3">
          <div className="col-md-6">
            <h4 style={{ color: "#0fa582" }}>
              <b> Due on 30/1/2024 – book today to reserve a space!</b>
            </h4>
          </div>
          <div className="col-md-5 text-end">
            <Button variant="primary" onClick={() => handleSaveStepFourData(1)}>
              Add to basket
            </Button>
            <Button variant="light" onClick={() => toggleStep("step1")}>
              Learn More <i className="fa-solid fa-caret-down" />
            </Button>
            <h5>
              <b>* price might be adjusted based on your workshop selection</b>
            </h5>
          </div>
          {/* collapse section */}
          <div className="row">
            <div className="col-lg-12">
              {openSteps.step1 && (
                <div id="example-collapse-text2">
                  <div className="row">
                    <div className="col-lg-8 mt-5">
                      <p>
                        Our MOT's start from just £39.99. Workshops are allowed
                        by the DVSA, who govern MOT tests, to charge you up to
                        £54.85 for your annual MOT test. So that's a saving of
                        up to £14.85!
                      </p>
                      <div className="d-flex flex-row icon_sections">
                        <i className="fas fa-key" />
                        <h6>
                          <b>
                            Select a top performing workshop close to your work
                            or home
                          </b>
                        </h6>
                      </div>
                      <div className="d-flex flex-row mt-5 icon_sections">
                        <i className="fa-solid fa-thumbs-up" />
                        <h6>
                          <b>Check your MOT result is fair</b>
                        </h6>
                      </div>
                      <div className="d-flex flex-row mt-5 icon_sections">
                        <i className="fa-solid fa-comment-dots" />
                        <h6>
                          <b>
                            Negotiate labour times and parts prices on any
                            repairs required
                          </b>
                        </h6>
                      </div>
                      <div className="d-flex flex-row mt-2 icon_sections">
                        <h6>
                          All work is guaranteed for 12 months. If you have a
                          car warranty we’ll seek authority for repairs to be
                          covered. We deal with any workshop problems, so you
                          don't have to.
                        </h6>
                      </div>
                      <div>
                        <h6>
                          <b>What's included:</b>
                        </h6>
                      </div>
                      <div className="d-flex flex-row mt-5 icon_sections">
                        <i className="fa-solid fa-car-side" />
                        <h6>
                          <b>Official DVSA MOT test</b>
                        </h6>
                      </div>
                      <div className="d-flex flex-row mt-3 icon_sections">
                        <i className="fa-solid fa-rotate-right" />
                        <h6>
                          <b>Free retest if required</b>
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-3 text-end mt-5">
                      <Button variant="light" className="p-3">
                        MOT Document
                      </Button>
                      <br />
                      <Button variant="light" className="mt-4 p-3">
                        Which garage?
                      </Button>
                    </div>
                  </div>
                  <hr style={{ border: "1px solid black" }} />
                  <div className="col-lg-12">
                    <h6>
                      <b>Customise Your Booking</b>
                    </h6>
                    <div className="row d-flex flex-column">
                      <div className="col-lg-4 d-flex flex-column">
                        <div className="d-flex mt-2 justify-content-between">
                          <label htmlFor="javascript:void(0)">Booking Date</label>
                        </div>
                        <input
                          className="mb-5"
                          type="date"
                          name="bookingDate"
                          value={stepFourData[0].bookingDate}
                          onChange={(event) => handleInputChange(event, 1)}
                        />
                      </div>
                      <h5 className="mt-1 mb-3">
                        The further away your MOT is, the more months you can
                        spread payments over.
                      </h5>
                    </div>
                  </div>
                  <hr style={{ border: "1px solid black" }} />
                  <div className="col-lg-8 d-flex flex-row icons_set_payment">
                    <i className="fa-solid fa-calendar-days mt-4" />
                    <div className="">
                      <h5>
                        <b>Payment options</b>
                      </h5>
                      <h6>
                        Reserve booking in at least two months in advance to
                        unlock option to pay in instalments.
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-5">
                    <h6 className="mb-4">
                      You can change all the details nearer the time, but to
                      reserve the best workshop in advance for you, it helps if
                      you set an initial date now and confirm the address you
                      want the workshop to be near.
                    </h6>
                    <p className="mt-4">
                      <b>Enter Postcode You Need The Workshop Close To</b>
                    </p>
                    <div className="lookup_input">
                      <input
                        type="text"
                        placeholder="Enter Your Postcode"
                        name="postcode"
                        value={stepFourData[0].postcode}
                        onChange={(event) => handleInputChange(event, 1)}
                      />
                      <Button>Lookup</Button>
                    </div>
                    <div className="mt-5">
                      <p>
                        <strong>Optional</strong> - Please add any comments or
                        notes that you like to make us aware of.
                      </p>
                      <div className="mb-2">
                        <label htmlFor="" className="form-label" />
                        <textarea
                          className="form-control"
                          name=""
                          id=""
                          rows={10}
                          defaultValue={""}
                        />
                      </div>
                      <p>
                        <span style={{ color: "red" }}>*</span> price might be
                        adjusted based on your workshop selection
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* ============================= collapse Second row ========================== */}
        <div className="row collapse_sec_row">
          <div className="row collaspe_button">
            <div className="col-lg-3">
              <button
                className={`service-btn ${
                  activePlan === "local" ? "active" : ""
                }`}
                data-plan="local"
                onClick={() => handleButtonClick("local")}
              >
                <p>
                  <b>
                    Local Recovery <br />
                    £36.30
                  </b>
                </p>
              </button>
            </div>
            <div className="col-lg-3">
              <button
                className={`service-btn ${
                  activePlan === "nationwide" ? "active" : ""
                }`}
                data-plan="nationwide"
                onClick={() => handleButtonClick("nationwide")}
              >
                <p>
                  <b>
                    Nationwide Recovery <br />
                    £54.30
                  </b>
                </p>
              </button>
            </div>
            <div className="col-lg-3">
              <button
                className={`service-btn ${
                  activePlan === "home" ? "active" : ""
                }`}
                data-plan="home"
                onClick={() => handleButtonClick("home")}
              >
                <p>
                  <b>
                    Nationwide Recovery and Home Assist
                    <br />
                    £12.30
                  </b>
                </p>
              </button>
            </div>
            <div className="col-lg-3">
              <button
                className={`service-btn ${
                  activePlan === "europewide" ? "active" : ""
                }`}
                data-plan="europewide"
                onClick={() => handleButtonClick("europewide")}
              >
                <p>
                  <b>
                    Europewide Recovery and Home Assist
                    <br />
                    £140.30
                  </b>
                </p>
              </button>
            </div>
          </div>
          <div className="row">
            {/* ==================== first section ========================== */}
            {activePlan === "local" && (
              <div className="service-content active content" id="local">
                <div className="row">
                  <div className="col-md-6">
                    <h4 style={{ color: "#0fa582" }}>
                      <b>
                        Relax with 24/7 roadside assistance – for help when you
                        need it
                      </b>
                    </h4>
                  </div>
                  <div className="col-md-5 text-end">
                    <button className="btn btn-primary">Add to basket</button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => toggleStep("step2")}
                    >
                      Learn More <i className="fa-solid fa-caret-down" />
                    </button>
                  </div>
                </div>
                {/* second_section */}
                {openSteps.step2 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <p>
                          <b>
                            Get back on your way quickly, with 80% of failures
                            repaired at the roadside.
                          </b>
                        </p>
                        <p>
                          Our nationwide and home assist level of breakdown
                          cover, protects your vehicle for faults and recovery
                          anywhere in the UK.
                        </p>
                      </div>
                      <div className="text-end btn_combination">
                        <button className="btn ms-1 btn-outline-dark border-2">
                          Breakdown cover document{" "}
                        </button>
                        <button className="btn ms-1 btn-outline-dark border-2">
                          Insurance Product Information Document (IPID)
                        </button>
                      </div>
                      <hr style={{ borderBottom: "1px solid black" }} />
                      <div className="row thrid_section_of_icon">
                        <div className="col-md-1">
                          <i className="fa-regular fa-clock mt-3" />
                        </div>
                        <div className="col-md-6">
                          <h4>
                            <b>Duration</b>
                          </h4>
                          <h4>How long are you looking to keep the car?</h4>
                        </div>
                        <div className="col-md-5">
                          <div className="timeline-container">
                            <div className="timeline">
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={12}
                                  />
                                </div>
                                <div className="label">12 months</div>
                              </label>
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={24}
                                  />
                                </div>
                                <div className="label">24 months</div>
                              </label>
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={36}
                                    defaultChecked=""
                                  />
                                </div>
                                <div className="label">36 months</div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ borderBottom: "1px solid black" }} />
                      <div className="row thrid_section_of_icon">
                        <div className="col-md-1">
                          <i className="fa-regular fa-calendar-days" />
                        </div>
                        <div className="col-md-6">
                          <h4>
                            <b>Start Date</b>
                          </h4>
                          <h4>
                            Choose the date when you would like your breakdown
                            cover to start.
                          </h4>
                        </div>
                        <div className="col-md-5">
                          <input type="date" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* ======================= Second section =================== */}
            {activePlan === "nationwide" && (
              <div className="service-content content" id="nationwide">
                <div className="row">
                  <div className="col-md-6">
                    <h4 style={{ color: "#0fa582" }}>
                      <b>
                        Relax with 24/7 roadside assistance – for help when you
                        need it with nationwide recovery
                      </b>
                    </h4>
                  </div>
                  <div className="col-md-5 text-end">
                    <button className="btn btn-primary">Add to basket</button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => toggleStep("step4")}
                    >
                      Learn More <i className="fa-solid fa-caret-down" />
                    </button>
                  </div>
                </div>
                {/* second_section */}
                {openSteps.step4 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <p>
                          <b>
                            Get back on your way quickly, with 80% of failures
                            repaired at the roadside.
                          </b>
                        </p>
                        <p>
                          Our nationwide and home assist level of breakdown
                          cover, protects your vehicle for faults and recovery
                          anywhere in the UK.
                        </p>
                      </div>
                      <div className="text-end btn_combination">
                        <button className="btn ms-1 btn-outline-dark border-2">
                          Breakdown cover document{" "}
                        </button>
                        <button className="btn ms-1 btn-outline-dark border-2">
                          Insurance Product Information Document (IPID)
                        </button>
                      </div>
                      <hr style={{ borderBottom: "1px solid black" }} />
                      <div className="row thrid_section_of_icon">
                        <div className="col-md-1">
                          <i className="fa-regular fa-clock mt-3" />
                        </div>
                        <div className="col-md-6">
                          <h4>
                            <b>Duration</b>
                          </h4>
                          <h4>How long are you looking to keep the car?</h4>
                        </div>
                        <div className="col-md-5">
                          <div className="timeline-container">
                            <div className="timeline">
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={12}
                                  />
                                </div>
                                <div className="label">12 months</div>
                              </label>
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={24}
                                  />
                                </div>
                                <div className="label">24 months</div>
                              </label>
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={36}
                                    defaultChecked=""
                                  />
                                </div>
                                <div className="label">36 months</div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ borderBottom: "1px solid black" }} />
                      <div className="row thrid_section_of_icon">
                        <div className="col-md-1">
                          <i className="fa-regular fa-calendar-days" />
                        </div>
                        <div className="col-md-6">
                          <h4>
                            <b>Start Date</b>
                          </h4>
                          <h4>
                            Choose the date when you would like your breakdown
                            cover to start.
                          </h4>
                        </div>
                        <div className="col-md-5">
                          <input type="date" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* ========================= Third section ================== */}
            {activePlan === "home" && (
              <div className="service-content content" id="home">
                <div className="row">
                  <div className="col-md-6">
                    <h4 style={{ color: "#0fa582" }}>
                      <b>
                        Relax with 24/7 roadside assistance – for help when you
                        need it with nationwide recovery and home assist
                      </b>
                    </h4>
                  </div>
                  <div className="col-md-5 text-end">
                    <button className="btn btn-primary">Add to basket</button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => toggleStep("step5")}
                    >
                      Learn More <i className="fa-solid fa-caret-down" />
                    </button>
                  </div>
                </div>
                {/* second_section */}
                {openSteps.step5 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <p>
                          <b>
                            Get back on your way quickly, with 80% of failures
                            repaired at the roadside.
                          </b>
                        </p>
                        <p>
                          Our nationwide and home assist level of breakdown
                          cover, protects your vehicle for faults and recovery
                          anywhere in the UK.
                        </p>
                      </div>
                      <div className="text-end btn_combination">
                        <button className="btn ms-1 btn-outline-dark border-2">
                          Breakdown cover document{" "}
                        </button>
                        <button className="btn ms-1 btn-outline-dark border-2">
                          Insurance Product Information Document (IPID)
                        </button>
                      </div>
                      <hr style={{ borderBottom: "1px solid black" }} />
                      <div className="row thrid_section_of_icon">
                        <div className="col-md-1">
                          <i className="fa-regular fa-clock mt-3" />
                        </div>
                        <div className="col-md-6">
                          <h4>
                            <b>Duration</b>
                          </h4>
                          <h4>How long are you looking to keep the car?</h4>
                        </div>
                        <div className="col-md-5">
                          <div className="timeline-container">
                            <div className="timeline">
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={12}
                                  />
                                </div>
                                <div className="label">12 months</div>
                              </label>
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={24}
                                  />
                                </div>
                                <div className="label">24 months</div>
                              </label>
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={36}
                                    defaultChecked=""
                                  />
                                </div>
                                <div className="label">36 months</div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ borderBottom: "1px solid black" }} />
                      <div className="row thrid_section_of_icon">
                        <div className="col-md-1">
                          <i className="fa-regular fa-calendar-days" />
                        </div>
                        <div className="col-md-6">
                          <h4>
                            <b>Start Date</b>
                          </h4>
                          <h4>
                            Choose the date when you would like your breakdown
                            cover to start.
                          </h4>
                        </div>
                        <div className="col-md-5">
                          <input type="date" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* ======================= Fourth section =================== */}
            {activePlan === "europewide" && (
              <div className="service-content content" id="europewide">
                <div className="row">
                  <div className="col-md-6">
                    <h4 style={{ color: "#0fa582" }}>
                      <b>
                        Relax with 24/7 roadside assistance – for help when you
                        need it with Europewide recovery and home assist
                      </b>
                    </h4>
                  </div>
                  <div className="col-md-5 text-end">
                    <button className="btn btn-primary">Add to basket</button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => toggleStep("step6")}
                    >
                      Learn More <i className="fa-solid fa-caret-down" />
                    </button>
                  </div>
                </div>
                {/* second_section */}
                {openSteps.step6 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <p>
                          <b>
                            Get back on your way quickly, with 80% of failures
                            repaired at the roadside.
                          </b>
                        </p>
                        <p>
                          Our nationwide and home assist level of breakdown
                          cover, protects your vehicle for faults and recovery
                          anywhere in the UK.
                        </p>
                      </div>
                      <div className="text-end btn_combination">
                        <button className="btn ms-1 btn-outline-dark border-2">
                          Breakdown cover document{" "}
                        </button>
                        <button className="btn ms-1 btn-outline-dark border-2">
                          Insurance Product Information Document (IPID)
                        </button>
                      </div>
                      <hr style={{ borderBottom: "1px solid black" }} />
                      <div className="row thrid_section_of_icon">
                        <div className="col-md-1">
                          <i className="fa-regular fa-clock mt-3" />
                        </div>
                        <div className="col-md-6">
                          <h4>
                            <b>Duration</b>
                          </h4>
                          <h4>How long are you looking to keep the car?</h4>
                        </div>
                        <div className="col-md-5">
                          <div className="timeline-container">
                            <div className="timeline">
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={12}
                                  />
                                </div>
                                <div className="label">12 months</div>
                              </label>
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={24}
                                  />
                                </div>
                                <div className="label">24 months</div>
                              </label>
                              <label className="timeline-item">
                                <div className="circle">
                                  <input
                                    type="checkbox"
                                    name="duration"
                                    value={36}
                                    defaultChecked=""
                                  />
                                </div>
                                <div className="label">36 months</div>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ borderBottom: "1px solid black" }} />
                      <div className="row thrid_section_of_icon">
                        <div className="col-md-1">
                          <i className="fa-regular fa-calendar-days" />
                        </div>
                        <div className="col-md-6">
                          <h4>
                            <b>Start Date</b>
                          </h4>
                          <h4>
                            Choose the date when you would like your breakdown
                            cover to start.
                          </h4>
                        </div>
                        <div className="col-md-5">
                          <input type="date" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {/* ============================= last one row ========================== */}
        <div className="row last_one_portion">
          <div className="row">
            <div className="col-md-6">
              <h4 style={{ color: "#0fa582" }}>
                <b> Due on 30/1/2024 – book today to reserve a space!</b>
              </h4>
            </div>
            <div className="col-md-5 text-end">
              <button
                className="btn btn-primary"
                onClick={() => handleSaveStepFourData(2)}
              >
                Add to basket
              </button>
              <button
                type="button"
                className="btn btn-light"
                onClick={() => toggleStep("step3")}
              >
                Learn More
                <i className="fa-solid fa-caret-down" />
              </button>
            </div>
            {openSteps.step3 && (
              <div className="row">
                <div className="col-lg-12 ">
                  <p>
                    Vehicle health checks are a critical aspect of maintaining
                    the longevity and efficiency of a vehicle. These checks
                    involve a thorough inspection of various components of the
                    vehicle, such as the engine, brakes, steering, suspension,
                    and tires, to ensure they are in good working order.
                  </p>
                  <h4
                    style={{ color: "#0fa582", marginTop: "20px !important" }}
                  >
                    <b>
                      Checks include a 20-point visual inspection of key working
                      components including
                    </b>
                  </h4>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>
                          Brake discs, drums, pads/shoes, pipes, and calipers
                        </h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Tyres</h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Wheel cylinders</h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Steering system fluids and condition</h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Shock absorbers</h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Cooling systems including fluids and hoses</h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Battery and charging system</h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Auxiliary belts</h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Windscreen wipers and bottle</h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>Exhaust system and emissions</h4>
                      </div>
                      <div className="icon_text d-flex  flex-row">
                        <i className="fa-solid fa-check" />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <h4>External body condition</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6  d-flex flex-column">
                      <h4
                        style={{
                          color: "#0fa582",
                          marginTop: "20px !important",
                        }}
                      >
                        <b>Customise Your Booking</b>
                      </h4>
                      <p>
                        <b>Booking Date:</b>
                      </p>
                      <input
                        type="date"
                        name="bookingDate2"
                        value={stepFourData[1].bookingDate2}
                        onChange={(event) => handleInputChange(event, 2)}
                      />
                      <p className="mt-5">
                        You can change all the details nearer the time, but to
                        reserve the best workshop in advance for you, it helps
                        if you set an initial date now and confirm the address
                        you want the workshop to be near.
                      </p>
                      <p>
                        <b>Enter Postcode You Need The Workshop Close To</b>
                      </p>
                      <div className="d-flex flex-row input_button">
                        <input
                          type="text"
                          name="postcode2"
                          value={stepFourData[1].postcode2}
                          onChange={(event) => handleInputChange(event, 2)}
                        />
                        <button className="btn ms-1 btn-outline-dark border-2">
                          Look Up
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr style={{ color: "black", border: "1px solid black" }} />
                  <div className="col-lg-6">
                    <p>
                      <strong>Optional</strong> - Please add any comments or
                      notes that you would like to make us aware of.
                    </p>
                    <div className="mb-3">
                      <label htmlFor="" className="form-label" />
                      <textarea
                        className="form-control"
                        name=""
                        id=""
                        rows={10}
                        placeholder="Enter Your Comment"
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="two_step_button d-flex justify-content-between mt-5">
          <button className="text-start" onClick={() => setActiveStep(3)}>Previous step</button>
          <button className="text-end" onClick={() => setActiveStep(5)} >Next step -Finalise</button>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
