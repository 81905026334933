import React, { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./dashboard.css";
import Sidebar from "../../../Components/Sidebar";
import AdminHeader from "../../../Components/AdminHeader";
import RecentOrders from "../../../Components/RecentOrders";
import { db } from "../../../firebase"; // Ensure this is your Firebase configuration
import { collection, getDocs } from "firebase/firestore";

const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);

  useEffect(() => {
    const fetchOrders = async () => {
      const ordersCollection = collection(db, "Orders");
      const ordersSnapshot = await getDocs(ordersCollection);
      const ordersList = ordersSnapshot.docs.map((doc) => doc.data());
      setOrders(ordersList);

      // Initialize totals
      let income = 0;
      let expenses = 0;
      let credit = 0;

      // Calculate totals
      ordersList.forEach((order) => {
        const totalPrice = order.totalPrice || 0;

        switch (order.status) {
          case "Delivered":
            income += totalPrice;
            break;
          case "Pending":
            expenses += totalPrice;
            break;
          case "Cancelled":
            credit += totalPrice;
            break;
          default:
            break;
        }
      });

      // Log the calculated values for debugging
      console.log("Total Income: ", income);
      console.log("Total Expenses: ", expenses);
      console.log("Total Credit: ", credit);

      // Update state
      setTotalIncome(income);
      setTotalExpenses(expenses);
      setTotalCredit(credit);

      // Update the chart with dynamic data
      if (paymentChart) {
        paymentChart.data.datasets[0].data = [expenses];
        paymentChart.data.datasets[1].data = [income];
        paymentChart.data.datasets[2].data = [credit];
        paymentChart.update();
      }
    };

    fetchOrders();

    const ctx = document.getElementById("paymentChart").getContext("2d");
    let paymentChart;

    if (ctx) {
      paymentChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: ["Expenses", "Income", "Credit Limit"],
          datasets: [
            {
              label: "Expenses",
              data: [0], // Initially set to zero, will be updated dynamically
              backgroundColor: "#00cc44",
            },
            {
              label: "Income",
              data: [0], // Initially set to zero, will be updated dynamically
              backgroundColor: "#ffe700",
            },
            {
              label: "Credit Limit",
              data: [0], // Initially set to zero, will be updated dynamically
              backgroundColor: "#cccccc",
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                borderDash: [1, 1],
              },
            },
            x: {
              grid: {
                borderDash: [1, 1],
              },
            },
          },
        },
      });
    }

    return () => {
      if (paymentChart) {
        paymentChart.destroy();
      }
    };
  }, []);

  return (
    <>
      <AdminHeader />
      <div className="container-fluid p-0 dashboard_main">
        {/* Header */}
        <div className="row">
          <Sidebar />
          <div className="col-md-9 full_width">
            <div className="row pt-3 pb-2 mb-3 border-bottom">
              <div className="col-md-12 text-center">
                <h1 className="h2">Dashboard</h1>
              </div>
            </div>
            {/* Content */}
            <div className="content container">
              <div className="row">
                <div className="col-lg-4 mb-4 d-flex">
                  <div className="card w-100" style={{ height: "500px" }}>
                    <div className="card-body">
                      <div className="dot-block d-flex justify-content-between">
                        <h2 className="card-title">Balance</h2>
                        <span className="d-flex dots">
                          <i className="fas fa-ellipsis-h"></i>
                        </span>
                      </div>
                      <h6>Total Balance</h6>
                      <h3 className="card-text">
                        {totalIncome + totalExpenses + totalCredit}{" "}
                        <span className="span-usd">USD</span>
                      </h3>
                      <small className="small-update">
                        Last updated 1m ago <i className="fas fa-sync-alt"></i>
                      </small>
                      <div className="mt-3 row d-flex justify-content-between text-center">
                        <div className="d-flex col-lg-4 justify-content-between flex-column">
                          <div>
                            <span>Income</span>
                          </div>
                          <span>${totalIncome}</span>
                        </div>
                        <div className="d-flex col-lg-4 justify-content-between flex-column">
                          <span>Expenses</span>
                          <span>${totalExpenses}</span>
                        </div>
                        <div className="d-flex col-lg-4 justify-content-between flex-column">
                          <span>Credit Limit</span>
                          <span>${totalCredit}</span>
                        </div>
                      </div>
                      <div className="d-flex mt-3">
                        <div className="flex-fill" id="flex-fill-1"></div>
                        <div className="flex-fill mx-1" id="flex-fill-2"></div>
                        <div className="flex-fill" id="flex-fill-3"></div>
                      </div>
                      <div className="mt-3 justify-content-between text-center">
                        <div className="d-flex justify-content-between">
                          <span>Description</span>
                          <span>Value</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span>Average Annual Profit</span>
                          <span>
                            7.96% <i className="fas fa-arrow-up"></i>
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span>Average Annual Return</span>
                          <span>
                            3.42% <i className="fas fa-arrow-down"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* General Payment */}
                <div className="col-lg-8 mb-4 d-flex">
                  <div className="card w-100" style={{ height: "500px" }}>
                    <div className="card-body">
                      <h5 className="card-title">General Payment</h5>
                      <div className="d-flex justify-content-between align-items-center">
                        <h3 className="card-text">
                          {totalIncome + totalExpenses + totalCredit}{" "}
                          <span className="span-usd">USD</span>
                        </h3>
                        <div className="dropdown">
                          <button
                            type="button"
                            id="btn-today"
                            className="btn btn-outline-secondary dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            Today
                          </button>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="btn-today"
                          >
                            <li>
                              <a className="dropdown-item" href="javascript:void(0)">
                                Normal
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="javascript:void(0)">
                                Active
                              </a>
                            </li>
                            <li>
                              <a className="dropdown-item" href="javascript:void(0)">
                                Disabled
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <small className="small-update">
                        Last updated 1m ago{" "}
                        <i className="fa-solid fa-rotate"></i>
                      </small>
                      <canvas id="paymentChart" className="mt-3"></canvas>
                    </div>
                  </div>
                </div>
                {/* Plannings Card */}
                <div className="col-lg-4 mb-4 d-flex">
                  <div className="card w-100" style={{ height: "400px" }}>
                    <div className="card-body">
                      <div className="dot-block d-flex justify-content-between">
                        <h2 className="card-title">Plannings</h2>
                        <span className="d-flex dots">
                          <i className="fas fa-ellipsis-h"></i>
                        </span>
                      </div>
                      <div className="mt-3">
                        <div className="planning-item d-flex justify-content-between align-items-center mt-3">
                          <div className="d-flex align-items-center">
                            <i className="fas fa-laptop planning-icon"></i>
                            <div className="planning-info">
                              <span className="planning-title">
                                New Laptop M1
                              </span>
                              <span className="planning-amount">
                                300 USD / 1,200 USD
                              </span>
                            </div>
                          </div>
                          <div
                            id="progress-laptop"
                            className="progress-circle"
                            data-progress="40"
                          >
                            <span>40%</span>
                          </div>
                        </div>
                        <div className="planning-item d-flex justify-content-between align-items-center mt-3">
                          <div className="d-flex align-items-center">
                            <i className="fas fa-home planning-icon"></i>
                            <div className="planning-info">
                              <span className="planning-title">
                                Dream House
                              </span>
                              <span className="planning-amount">
                                800 USD / 120,000 USD
                              </span>
                            </div>
                          </div>
                          <div
                            id="progress-house"
                            className="progress-circle"
                            data-progress="80"
                          >
                            <span>80%</span>
                          </div>
                        </div>
                        <div className="planning-item d-flex justify-content-between align-items-center mt-3">
                          <div className="d-flex align-items-center">
                            <i className="fas fa-car planning-icon"></i>
                            <div className="planning-info">
                              <span className="planning-title">
                                Get a New Car
                              </span>
                              <span className="planning-amount">
                                1,000 USD / 24,300 USD
                              </span>
                            </div>
                          </div>
                          <div
                            id="progress-car"
                            className="progress-circle"
                            data-progress="20"
                          >
                            <span>20%</span>
                          </div>
                        </div>
                        <div className="planning-item d-flex justify-content-between align-items-center mt-3">
                          <div className="d-flex align-items-center">
                            <i className="fas fa-mobile-alt planning-icon"></i>
                            <div className="planning-info">
                              <span className="planning-title">New iPhone</span>
                              <span className="planning-amount">
                                800 USD / 1,500 USD
                              </span>
                            </div>
                          </div>
                          <div
                            id="progress-iphone"
                            className="progress-circle"
                            data-progress="60"
                          >
                            <span>60%</span>
                          </div>
                        </div>
                        <div className="planning-item d-flex justify-content-between align-items-center mt-3">
                          <div className="d-flex align-items-center">
                            <i className="fas fa-plane planning-icon"></i>
                            <div className="planning-info">
                              <span className="planning-title">
                                Summer Vacation
                              </span>
                              <span className="planning-amount">
                                4,000 USD / 6,000 USD
                              </span>
                            </div>
                          </div>
                          <div
                            id="progress-vacation"
                            className="progress-circle"
                            data-progress="50"
                          >
                            <span>50%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Transaction History */}
                <div className="col-lg-8 mb-4 d-flex">
                  <div className="card w-100" style={{ height: "400px" }}>
                    <div className="card-body">
                      <h5 className="card-title">Transaction History</h5>
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Transaction ID</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>20 Jul 2022</td>
                            <td>#TRX1001</td>
                            <td>Payment for Services</td>
                            <td>$500</td>
                            <td>
                              <span className="badge bg-success">
                                Completed
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>19 Jul 2022</td>
                            <td>#TRX1002</td>
                            <td>Refund for Item</td>
                            <td>$200</td>
                            <td>
                              <span className="badge bg-danger">Failed</span>
                            </td>
                          </tr>
                          <tr>
                            <td>18 Jul 2022</td>
                            <td>#TRX1003</td>
                            <td>Payment for Goods</td>
                            <td>$1,000</td>
                            <td>
                              <span className="badge bg-warning">Pending</span>
                            </td>
                          </tr>
                          <tr>
                            <td>17 Jul 2022</td>
                            <td>#TRX1004</td>
                            <td>Payment for Subscription</td>
                            <td>$50</td>
                            <td>
                              <span className="badge bg-success">
                                Completed
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <RecentOrders />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
