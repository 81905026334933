import React from "react";
import "../../Pages/Pricing/Pricing.css";
// import acura from "../../assets/Company Logo/hyundai-logo.webp"
import aston from "../../assets/Company Logo/aston-martin-logo.png"
import audi from "../../assets/Company Logo/audi-logo.png"
import bmw from "../../assets/Company Logo/bmw-logo.png"
import Toyota from "../../assets/Company Logo/nissan-logo.png"
import Nissan from "../../assets/Company Logo/nissan-logo.png"
import Volkswagen from "../../assets/Company Logo/volkswagen-logo.webp"
import DYC from "../../assets/Company Logo/dyc.svg"
import Porsche from "../../assets/Company Logo/porsche-logo.webp"
import chrysler from "../../assets/Company Logo/chrysler-logo.png"
import LandRover from "../../assets/Company Logo/land-rover.svg"
import Tesla from "../../assets/Company Logo/tesla-logo.png"
import romeo from "../../assets/Company Logo/alfa-romeo-logo.webp"
import ferrari from "../../assets/Company Logo/ferrari-logo.png"
import ford from "../../assets/Company Logo/ford-logo.png"
import Mitsubishi from "../../assets/Company Logo/mitsubishi-logo.png"
import gmc from "../../assets/Company Logo/gmc-logo.png"
import honda from "../../assets/Company Logo/honda-logo.webp"
import hundai from "../../assets/Company Logo/hyundai-logo.webp"
import infiniti from "../../assets/Company Logo/infiniti-logo.webp"
import jaguar from "../../assets/Company Logo/jaguar-logo.png"
import jeep from "../../assets/Company Logo/jeep-logo.png"
import kia from "../../assets/Company Logo/kia-logo.webp"
import lamborghini from "../../assets/Company Logo/lamborghini-logo.webp"
import lexus from "../../assets/Company Logo/lexus-logo.webp"
function ComapniesAttached() {
  return (
    <>
      <div className="container mt-4">
        <div className="Pricing mt-5 mb-3">
          <h2 className="text-center">Which Manufactures Do You Cover ?</h2>
          <h5 style={{ color: "#2bace7" }} className=" text-center mt-4 mb-4">
            Motoreasy Provides Car Warranties For all Mojor car Manufactures
            Included:
          </h5>
        </div>
        {/* First Row */}
        <div className="row company_car_logo">
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={hundai}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Hyundai</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={romeo}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Alfa Romeo</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={aston}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Aston Martin</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={audi}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Audi</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={Toyota}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Toyota</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={bmw}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">BMW</h4>
              </div>
            </div>
          </div>
        </div>
        {/* Second row */}
        <div className="row company_car_logo mt-4">
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={Nissan}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Nissan</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={Volkswagen}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Volkswagen</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={DYC}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">DYC </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={Porsche}

                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Porsche</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={chrysler}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Chrysler</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={LandRover}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Land Rover</h4>
              </div>
            </div>
          </div>
        </div>
        {/* Third Row */}
        <div className="row company_car_logo mt-4">
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={Tesla}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Tesla </h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={ferrari}

                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Ferrari</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={ford}

                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Ford</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={Mitsubishi}

                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Mitsubishi</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={audi}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Audi</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={honda}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Honda</h4>
              </div>
            </div>
          </div>
        </div>
        {/* Fourth Row  */}
        <div className="row company_car_logo mt-4">
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={infiniti}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Infiniti</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={jaguar}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Jaguar</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={jeep}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Jeep</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={kia}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Kia</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={lamborghini}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Lamborghini</h4>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 mt-4">
            <div className="card text-center mx-auto">
              <img
                className=""
                src={lexus}
                alt="Title"
              />
              <div className="card-body">
                <h4 className="card-title">Lexus</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ComapniesAttached;