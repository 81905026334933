import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select"; // Import react-select
import { toast } from "react-toastify";
import "./SearchFilter.css";
import backgroundImage from "../../assets/images/window 01.png"; // Import the image

const manufacturers = [
  { value: "Toyota", label: "Toyota" },
  { value: "Ford", label: "Ford" },
  { value: "Honda", label: "Honda" },
  { value: "BMW", label: "BMW" },
  { value: "Audi", label: "Audi" },
  { value: "Mercedes-Benz", label: "Mercedes-Benz" },
  { value: "Nissan", label: "Nissan" },
  { value: "Chevrolet", label: "Chevrolet" },
  { value: "Volkswagen", label: "Volkswagen" },
];

// Dummy list of years
const years = Array.from(new Array(30), (val, index) => {
  return { value: 2021 - index, label: `${2021 - index}` };
});


const SearchComponent = () => {
  const [vehicleType, setVehicleType] = useState("car"); // Vehicle type state
  const [makers, setMakers] = useState([]);
  const [selectedCar, setSelectedCar] = useState("");
  const [selectedMaker, setSelectedMaker] = useState("");
  const [selectedYear, setSelectedYear] = useState(null); // Added Year selection
  const [models, setModels] = useState([]);
  const [cars, setCars] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null); // Manufacturer state
  const navigate = useNavigate();

  // Fetch all cars on component mount
  useEffect(() => {
    const fetchCars = async () => {
      try {
        const response = await axios.get(
          "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars"
        );
        if (response.data && Array.isArray(response.data.cars)) {
          setCars(response.data.cars);
        } else {
          console.error("Unexpected response data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching car data:", error);
      }
    };

    fetchCars();
  }, []);

  // Fetch makers based on selected car
  useEffect(() => {
    const fetchMakers = async () => {
      if (!selectedCar) return;
      try {
        const response = await axios.get(
          `https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-maker?car_id=${selectedCar}`
        );
        if (response.data && Array.isArray(response.data.maker)) {
          setMakers(response.data.maker);
        } else {
          console.error("Unexpected response data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching makers:", error);
      }
    };

    fetchMakers();
  }, [selectedCar]);

  // Fetch models based on selected car and maker
  useEffect(() => {
    const fetchModels = async () => {
      if (!selectedCar || !selectedMaker) return;
      try {
        const response = await axios.get(
          "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/all-cars-models",
          {
            params: {
              car_id: selectedCar,
              maker_id: selectedMaker,
            },
          }
        );
        if (response.data && Array.isArray(response.data.models)) {
          setModels(response.data.models);
        } else {
          console.error("Unexpected response data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
      }
    };

    fetchModels();
  }, [selectedCar, selectedMaker]);


  const handleCarModelChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedYear(selectedValue);


  };

  // Fetch car details and navigate to product details page
  const fetchCarDetails = async () => {
    if (!selectedCar || !selectedMaker) {
      toast.error("Selected car ID is empty or invalid.");
      return;
    }

    try {
      const response = await axios.post(
        "https://phplaravel-1324103-4877027.cloudwaysapps.com/api/get-car-img",
        {
          car_id: selectedCar,
          maker_id: selectedMaker,
          model_id: selectedYear,
        }
      );

      if (response.data && Array.isArray(response.data.all_images)) {
        const carImages = response.data.all_images.map((img) => ({
          ...img,
          url: `${response.data.imagepath}${img.car_image}`,
        }));

        navigate("/product-details", {
          state: {
            carImages,
            selectedCarName: response.data.car_name,
            selectedModelName: response.data.model_name,
            car_id: selectedCar,
            maker_id: selectedMaker,
            model_id: selectedYear,
          },
        });
      } else {
        console.error("Unexpected response data format:", response.data);
        navigate("/product-details", {
          state: { error: "An error occurred while fetching the car images." },
        });
      }
    } catch (error) {
      console.error("Error fetching car images:", error);
      navigate("/product-details", {
        state: { error: "An error occurred while fetching the car images." },
      });
    }
  };

  return (
    <div
      className="adjust-padding container-fluid d-flex justify-content-center align-items-center"
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${backgroundImage})`, // Correct usage of backgroundImage
        backgroundSize: "cover",
        backgroundPosition: "center",
        // padding: "40px",
      }}
    >
      <Container
        fluid
        className="p-md-5 p-2 " style={{ maxWidth: "1100px", }}

      >
        <Row className="text-center mb-4 mt-md-0 mt-2 g-0">
          <Col>
            <h1 className="fw-bold fs-1" style={{ color: "#002e5a" }}>
              Search a Product for your Vehicle
            </h1>
            <h4 className="" style={{ color: '#002e5a' }}>Inspection, Service, Warranty  </h4>
          </Col>
        </Row>
        <Form>
          <Row className="mb-3 g-0">

            <div className="row row-style">
              <div className="row">
                <div className="col-lg-6">
                  <Col xs={12} md={12} className="d-flex flex-wrap justify-content-between " style={{ color: "#002e5a" }}>
                    <Form.Label>Vehicle</Form.Label>
                    <div className=" ms-4 d-flex flex-wrap justify-content-evenly">
                      <Form.Check
                        type="radio"
                        label="Car"
                        name="vehicleType"
                        id="car"
                        inline
                        checked={vehicleType === "car"}
                        onChange={() => setVehicleType("car")}

                      />

                      <Form.Check
                        type="radio"
                        label="Van"
                        name="vehicleType"
                        id="Van"
                        inline
                        checked={vehicleType === "Van"}
                        onChange={() => setVehicleType("Van")}
                      />

                      <Form.Check
                        type="radio"
                        label="motorhome"
                        name="vehicleType"
                        id="motorhome"
                        inline
                        checked={vehicleType === "motorhome"}
                        onChange={() => setVehicleType("motorhome")}
                      />

                      <Form.Check
                        type="radio"
                        label="motorcycle"
                        name="vehicleType"
                        id="motorcycle"
                        inline
                        checked={vehicleType === "motorcycle"}
                        onChange={() => setVehicleType("motorcycle")}
                      />
                    </div>

                  </Col>
                </div>
              </div>
              <div className="col-lg-6">
                <Col xs={12} md={12}>
                  <Form.Group
                    className="row mt-2  justify-content-center d-flex align-items-center " style={{ color: "#002e5a" }}
                    controlId="make"
                  >
                    <div className="col-sm-3">
                      <Form.Label>Make</Form.Label>
                    </div>
                    <div className="col-sm-9 ">
                      <Form.Control
                        style={{ padding: "15px" }}
                        as="select"
                        value={selectedCar}
                        onChange={(e) => setSelectedCar(e.target.value)}
                      >
                        <option value="">- Make -</option>
                        {cars.map((car) => (
                          <option key={car.id} value={car.id}>
                            {car.car_name}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Form.Group
                    className="row mt-2  justify-content-center d-flex align-items-center " style={{ color: "#002e5a" }}
                    controlId="model"
                  >
                    <div className="col-sm-3">
                      <Form.Label>Model</Form.Label>
                    </div>
                    <div className="col-sm-9 ">
                      <Form.Control
                        style={{ padding: "15px" }}
                        as="select"
                        value={selectedMaker}
                        onChange={(e) => setSelectedMaker(e.target.value)}
                      >
                        <option value="">- Model -</option>
                        {makers.map((maker) => (
                          <option key={maker.id} value={maker.id}>
                            {maker.maker_name}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={12} md={12}>
                  <Form.Group
                    className="row mt-2  justify-content-center d-flex align-items-center " style={{ color: "#002e5a" }}
                    controlId="yearSelect"
                  >
                    <div className="col-sm-3 " style={{ color: "#002e5a" }}>
                      <Form.Label>Year</Form.Label>
                    </div>
                    <div className="col-sm-9 ">
                      <Form.Control
                        as="select"
                        id="year"
                        value={selectedYear}
                        onChange={handleCarModelChange}
                        style={{ padding: "15px" }}
                      >
                        <option value="">-year-</option>
                        {models.map((model, index) => (
                          <option key={index} value={model.id}>
                            {model.model_name}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>

              </div>
              <div className="col-lg-6">
                <Col xs={12} md={12}>
                  <Form.Group
                    className="row mt-2 justify-content-center d-flex align-items-center  mt-4"
                    controlId="Engine Size"
                  >
                    <div className="col-sm-3">
                      <Form.Label>Engine Size</Form.Label>
                    </div>
                    <div className="col-sm-9 ">
                      <Form.Control as="select" style={{ padding: "15px" }}>
                        <option value="">- Engine Size -</option>
                        <option value="3 cylinder">3 cylinder</option>
                        <option value="4 cylinder">4 cylinder</option>
                        <option value="5 cylinder">5 cylinder</option>
                        <option value="6 cylinder">6 cylinder</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>

                {/* Manufacturer   Comment on the base of  clinet  Review  */}

                {/* <Col xs={12} md={12}>
                  <Form.Group
                    className="row mt-2 justify-content-center d-flex align-items-center "
                    controlId="manufacturer"
                  >
                    <div className="col-sm-3 " style={{ color: "#002e5a" }}>
                      <Form.Label>Manufacturer</Form.Label>
                    </div>
                    <div className="col-sm-9">
                      <Select
                        styles={{
                          control: (base) => ({
                            ...base,
                            padding: "10px",
                            borderRadius: "5px",
                            borderColor: "#ced4da",
                            minHeight: "20px",
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 999,
                          }),
                        }}
                        options={manufacturers}
                        value={selectedManufacturer}
                        onChange={(selectedOption) =>
                          setSelectedManufacturer(selectedOption)
                        }
                      />
                    </div>
                  </Form.Group>
                </Col>
                 */}

                <Col xs={12} md={12}>
                  <Form.Group className="row mt-2 justify-content-center align-items-center" controlId="CurrentKm">
                    <div className="col-sm-3" style={{ color: "#002e5a" }}>
                      <Form.Label>Current Km</Form.Label>
                    </div>
                    <div className="col-sm-9">
                      <Form.Control
                        type="text"
                        placeholder="Enter Current Km"
                        style={{
                          padding: "10px",
                          borderRadius: "5px",
                          borderColor: "#ced4da",
                          minHeight: "20px",
                        }}
                      />
                    </div>
                  </Form.Group>
                </Col>


                <Col xs={12} md={12}>
                  <Form.Group
                    className="row  mt-2 justify-content-center d-flex align-items-center " style={{ color: "#002e5a" }}
                    controlId="partsType"
                  >
                    <div className="col-sm-3">
                      <Form.Label>Fuel Type</Form.Label>
                    </div>
                    <div className="col-sm-9 ">
                      <Form.Control as="select" style={{ padding: "15px" }}>
                        <option value="">- Fuel Type -</option>
                        <option value="1">Petrol</option>
                        <option value="2">Diesel</option>
                        <option value="2">Hybrid</option>
                        <option value="2">EV</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
                <Col xs={12} className="text-end mt-2">
                  <Button
                    variant="primary"
                    onClick={fetchCarDetails}
                    style={{
                      backgroundColor: "#007bff",
                      padding: "10px 70px",
                      marginTop: "30px",
                      fontSize: "1.2rem",
                      borderRadius: "5px",
                    }}
                  >
                    Show
                  </Button>
                </Col>
              </div>
            </div>
          </Row>
          <Row className="mb-3">
            {/* <Col xs={12} md={12}>
              <Form.Group controlId="priceFrom">
                <Form.Label>Price</Form.Label>
                <Form.Control type="number" placeholder="from ($)" />
              </Form.Group>
            </Col>
            <Col xs={12} md={12}>
              <Form.Group controlId="priceTo">
                <Form.Label>&nbsp;</Form.Label>
                <Form.Control type="number" placeholder="to ($)" />
              </Form.Group>
            </Col> */}
          </Row>
        </Form>
      </Container>
    </div >
  );
};

export default SearchComponent;
