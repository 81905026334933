// src/emailService.js

import axios from "axios";

export const sendEmail = async (invoiceData, userDetails) => {
  const apiKey = "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"; // Your Brevo API key
  const endpoint = "https://api.brevo.com/v3/smtp/email";

  const emailData = {
    sender: { name: "Motor Easy", email: "muhammadfayyaz0097@gmail.com" }, // Replace with a verified email
    to: [{ email: userDetails.email, name: userDetails.name }],
    subject: "Your Motor Easy Invoice",
    htmlContent: `
      <html>
        <body>
          <h1>Motor Easy Invoice</h1>
          <p>Dear ${userDetails.name},</p>
          <p>Thank you for your purchase! Please find your invoice attached.</p>
          <p>Total: $${invoiceData.total}</p>
        </body>
      </html>
    `,
    attachment: [
      {
        content: invoiceData.pdfBase64,
        name: "invoice.pdf",
        type: "application/pdf",
      },
    ],
  };
  try {
    await axios.post(endpoint, emailData, {
      headers: {
        "api-key": apiKey,
        "Content-Type": "application/json",
        accept: "application/json",
      },
    });
    console.log("Email sent successfully.");
  } catch (error) {
    console.error("Error sending email:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
    }
  }
};
