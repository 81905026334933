import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./sidebar.css"; // Ensure you have the CSS file for custom styles
import { Link } from "react-router-dom";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState({
    services: false,
    team: false,
  });

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = (dropdown) => {
    setDropdownOpen({ ...dropdownOpen, [dropdown]: !dropdownOpen[dropdown] });
  };

  return (
    <div className={`col-md-3 pb-5 mb-5 px-0 ${isOpen ? "" : " close_sidebar"}`}
    style={{width: "20% "}}
    >
      {!isOpen && (
        <button
          className="btn "
          onClick={toggleSidebar}
          style={{ margin: "10px" }}
        >
          <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
        </button>
      )}
      <div
        className={`sidebar bg-light border-end p-1 pb-5 mb-5 ${
          isOpen ? "open" : ""
        }`}
        style={{
          width: isOpen ? "260px" : "0",
          transition: "width 0.3s",
          overflowX: "hidden",
          position: "fixed",
          left: "0",
          transform: isOpen ? "translateX(0px)" : "translateX(-300px)",
          height: "100%",
        }}
      >
        <ul className="list-unstyled pb-5">
          <li className="d-flex align-items-center justify-content-between">
            <h2>Menu</h2>
            <button
              className="btn"
              onClick={toggleSidebar}
              style={{ margin: "10px" }}
            >
              <i className={isOpen ? "fas fa-times" : "fas fa-bars"}></i>
            </button>
          </li>
          <li className="p-1">
            <Link to="/admin/Dashboard">
              <i className="fas fa-tachometer-alt"></i> Dashboard
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/AddPost">
              <i className="fas fa-pen-nib"></i> Blog
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/Appointments">
              <i className="fas fa-calendar-alt"></i> Appointments Data
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/Vehicles-Data">
              <i className="fas fa-car"></i> Vehicle Data
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/All_Products">
            <i class="fa-brands fa-product-hunt"></i> All Products
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/Upload-banners">
              <i className="fas fa-image"></i> Upload Banners
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/Add-Pricings">
              <i className="fas fa-dollar-sign"></i> Add Pricings
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/Add-Images">
              <i className="fas fa-upload"></i> Add Model Images
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/Create-Coupans">
              <i className="fas fa-tags"></i> Create Coupons
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/Cart-Data">
              <i className="fas fa-shopping-cart"></i> Cart Data
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/ManageUsers">
              <i className="fas fa-users"></i> Manage Users
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/Message-Request">
              <i className="fas fa-envelope"></i> Contact Request
            </Link>
          </li>
          <li className="p-1">
            <Link to="/admin/All_Comments">
              <i className="fas fa-envelope"></i> Comments Request
            </Link>
          </li>
          <li className="p-1">
            <a href="javascript:void(0)" onClick={() => toggleDropdown("services")}>
              <i className="fas fa-boxes"></i> Orders{" "}
              <i className="fas fa-caret-down"></i>
            </a>
            {dropdownOpen.services && (
              <ul className="list-unstyled ps-3">
                <li className="p-2">
                  <Link to="/admin/Pending-Orders">
                    <i className="fas fa-hourglass-start"></i> Pending Orders
                  </Link>
                </li>
                <li className="p-2">
                  <Link to="/admin/Canceled-orders">
                    <i className="fas fa-check-circle"></i> Cancelled Orders
                  </Link>
                </li>
                <li className="p-2">
                  <Link to="/admin/displayorder">
                    <i className="fas fa-hourglass-start"></i> Display Orders
                  </Link>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
