import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import TopHeader from "../../Components/TopHeader";
import MainHeader from "../../Components/MainHeader";
import "../../Pages/Contactus/Contactus.css";
import Footer from "../../Components/Footer";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    rego: "",
    phone: "",
    postcode: "",
    store: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contacts"), formData);
      toast.success("Message submitted successfully");
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("Error submitting message");
    }
    setFormData({
      name: "",
      email: "",
      message: "",
      rego: "",
      phone: "",
      postcode: "",
      store: "",
    });
  };

  return (
    <>
      <TopHeader />
      <MainHeader />
      <div className="container-fluid">
        <div className="bag_ground_img d-flex align-items-center justify-content-center">
          <h2>Contact Us</h2>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-1" />
          <div className="col-lg-11 col-md-12 text-start set_form_width mb-4">
            <div className="set_font_size">
              <h4>
                <b>GET IN TOUCH WITH US</b>
              </h4>
              <p>
                Motreserve is here to help, we kindly request you complete the
                form below so we can assist you with your enquiry.
              </p>
              <p>
                For any urgent enquiries, our Motreserve Teams can be contacted
                on <strong>1300 758 377</strong>.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="name" className="form-label">
                      Name*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Enter name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="rego" className="form-label">
                      Rego
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="rego"
                      name="rego"
                      value={formData.rego}
                      onChange={handleChange}
                      placeholder="Enter registration number"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="phone" className="form-label">
                      Phone*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder="Enter phone number"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Email*
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Enter email"
                      required
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="postcode" className="form-label">
                      Postcode*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="postcode"
                      name="postcode"
                      value={formData.postcode}
                      onChange={handleChange}
                      required
                      placeholder="Enter your postcode"
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="store" className="form-label">
                      Store*
                    </label>
                    <select
                      className="form-select"
                      id="store"
                      name="store"
                      value={formData.store}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Select a store</option>
                      <option value="Warranty">Warranty</option>
                      <option value="Inspection">Inspection</option>{" "}
                      {/* Corrected spelling */}
                      <option value="Service">Service</option>
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    rows={4}
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Enter your message"
                  />
                </div>
                <div className="text-start">
                  <button type="submit" className="btn btn-outline-info">
                    SUBMIT ENQUIRY
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-1" />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
