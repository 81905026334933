import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const BlogSections = () => {
    const [blogsData, setBlogsData] = useState(null);

    useEffect(() => {
        const fetchBlogData = async () => {
            try {
                const blogsCollectionRef = collection(db, 'blogs');
                const querySnapshot = await getDocs(blogsCollectionRef);
                const blogs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setBlogsData(blogs);
            } catch (error) {
                console.error("Error fetching blog data: ", error);
            }
        };

        fetchBlogData();
    }, []);

    const renderCard = (blog, index) => (
        <div className="col-lg-4 col-sm-12 d-flex justify-content-center" key={index}>
            <div className="card">
                <img src={blog.imageUrl} alt={`Blog ${index + 1}`} className="card-img-top" />
                <div className="card-body">
                    <h3 className="card-title mt-4">{blog.title}</h3>
                    <div className="links d-flex justify-content-between px-2">
                        <Link className="ms-2" style={{ backgroundColor: "#002e5a", padding: "10px", color: "white", textDecoration: "none" }} to={`/blogs/${blog.id}`}> Read More </Link>
                        <Link to={"/getQuotes"} style={{ backgroundColor: "#002e5a", padding: "10px", color: "white", textDecoration: "none" }} className='text-end'>Get Quote</Link>
                    </div>
                </div>
            </div>
        </div>
    );

    const chunkArray = (array, size) => {
        const chunkedArr = [];
        for (let i = 0; i < array.length; i += size) {
            chunkedArr.push(array.slice(i, i + size));
        }
        return chunkedArr;
    };

    if (!blogsData) {
        return <div>Loading...</div>;
    }

    const chunkedBlogs = chunkArray(blogsData, 3);

    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 mt-5 mb-5">
                    <div className="text-center Great_customer">
                        <h5 className='fw-bold'>BLOG & NEWS</h5>
                        <h3 className='fw-bold'>See Latest Articles <span>insurance</span></h3>
                    </div>
                </div>
            </div>
            <div className="row">
                {/* Desktop Carousel */}
                <div className="d-none d-lg-block">
                    <Carousel controls={true} indicators={true} interval={null}>
                        {chunkedBlogs.map((chunk, index) => (
                            <Carousel.Item key={index}>
                                <div className="row blog_section d-flex justify-content-center g-0 d-flex m-0">
                                    {chunk.map(renderCard)}
                                    
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>

                {/* Mobile Carousel */}
                <div className="d-block d-lg-none">
                    <Carousel controls={true} indicators={true} interval={null}>
                        {blogsData.map((blog, index) => (
                            <Carousel.Item key={index}>
                                <div className="row blog_section d-flex justify-content-center g-0 d-flex m-0">
                                    {renderCard(blog, index)}
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
    );
};

export default BlogSections;
